import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MySwiper from "../components/swiper/swiper"
// import { LazyLoadImage } from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import tstl1 from "../images/1.jpg"
import tstl2 from "../images/2.jpg"
import tstl3 from "../images/3.jpg"
import Modal from "../components/modal/modal"
import BannerBg from "../images/box-bg.jpg"
import mission from "../images/goal.svg"
import vision from "../images/vision.svg"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <div
          className="container-fluid hero"
          style={{
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundImage: `url(${BannerBg})`,
          }}
        >
          <div className="row">
            <div className="col-8 m-auto">
              <div className="text-center">
                <h1
                  data-aos="fade-down"
                  data-aos-duration="600"
                  data-aos-delay="1000"
                >
                  To Make Our Customers' Move Experience Less Stressful
                </h1>
                <Modal className="btn btn-primary main-cta text-uppercase" />
              </div>
            </div>
          </div>
        </div>
        {/*<div className="our-work pt-5">
          <div className="subheader">
            <h3 data-aos="fade-down" data-aos-duration="600">
              Our Works
            </h3>
            <hr data-aos="fade" className="mx-auto" />
            <p
              className="text-black-50 subheading"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Lorem ipsum dolor sit amet blanditiis accusamus perferendis libero
              amet nisi accusamus.
            </p>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4">
                <div
                  data-aos="fade-right"
                  data-aos-duration="600"
                  data-aos-delay="100"
                  className="items"
                >
                  <Link className="items-link" to="/">
                    <img
                      className="img-fluid"
                      loading="lazy"
                      alt="corporate"
                      src="https://images.unsplash.com/photo-1505322747495-6afdd3b70760?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1020&amp;q=80&amp;h=768"
                    />
                    <div className="info">
                      <i className="icon-trophy text-white text-size-lg" />
                      <h5 className="mt-5 mb-2">Corporate Design</h5>
                      <p>Design</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  data-aos="fade-down"
                  data-aos-duration="600"
                  data-aos-delay="200"
                  className="items"
                >
                  <Link className="items-link" to="/">
                    <img
                      alt="social media"
                      className="img-fluid"
                      loading="lazy"
                      src="https://images.unsplash.com/photo-1522542550221-31fd19575a2d?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1020&amp;q=80&amp;h=768"
                    />
                    <div className="info">
                      <i className="icon-chart text-white text-size-lg" />
                      <h5 className="mt-5 mb-2">Social Media Marketing</h5>
                      <p>Marketing</p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  data-aos="fade-left"
                  data-aos-duration="600"
                  data-aos-delay="300"
                  className="items"
                >
                  <Link className="items-link" to="/">
                    <img
                      alt="website"
                      className="img-fluid"
                      loading="lazy"
                      src="https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;auto=format&amp;fit=crop&amp;w=1020&amp;q=80&amp;h=768"
                    />
                    <div className="info">
                      <i className="icon-layers text-white text-size-lg" />
                      <h5 className="mt-5 mb-2">Website Development</h5>
                      <p>Development</p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
        <div className="our-services">
          <div className="subheader">
            <h2 data-aos="fade-down" data-aos-duration="600">
              Our Services
            </h2>
            <hr data-aos="fade" />
            <p
              className="text-black-50 subheading"
              data-aos="fade-up"
              data-aos-duration="600"
            >
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Blanditiis accusamus perferendis liberoa dipisicing accusantium
              nisi.
            </p>
          </div>
          <div className="container">
            <div className="row">
              {/* <div className="col-md-3">
                <div
                  data-aos="fade-left"
                  data-aos-duration="600"
                  data-aos-delay="400"
                  className="info text-center"
                >
                  <i className="icon-chart" />
                  <h5>SEO</h5>
                  <p className="text-black-50">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Molestias id volume amet.
                  </p>
                </div>
              </div>*/}

              <div className="col-md-4">
                <div className="service-block">
                  <a title="Siding" href="/">
                    <div className="service-img">
                      <div className="service-txt"></div>
                      <div
                        className="service-bg"
                        style={{
                          backgroundImage:
                            "url(https://images.unsplash.com/photo-1548788345-0dacc0497176?ixlib=rb-1.2.1&auto=format&fit=crop&w=334&q=80)",
                        }}
                      ></div>
                    </div>
                  </a>
                  <div className="service-desc">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-center svc-content">
                          <h4>House Hold Moving</h4>
                          <hr />
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Molestias id volume amet.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="service-block">
                  <a title="Siding" href="/">
                    <div className="service-img">
                      <div className="service-txt"></div>
                      <div
                        className="service-bg"
                        style={{
                          backgroundImage:
                            "url(https://images.unsplash.com/photo-1566576721346-d4a3b4eaeb55?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=401&q=80)",
                        }}
                      ></div>
                    </div>
                  </a>
                  <div className="service-desc">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-center svc-content">
                          <h4>Labor Moves</h4>
                          <hr />
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Molestias id volume amet.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="service-block">
                  <a title="Siding" href="/">
                    <div className="service-img">
                      <div className="service-txt"></div>
                      <div
                        className="service-bg"
                        style={{
                          backgroundImage:
                            "url(https://images.unsplash.com/photo-1562534315-64dba645d0f9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=414&q=80)",
                        }}
                      ></div>
                    </div>
                  </a>
                  <div className="service-desc">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="text-center svc-content">
                          <h4>Moving Supplies</h4>
                          <hr />
                          <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing
                            elit. Molestias id volume amet.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="who-we-are">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <div className="info pr-0 pr-md-5 who-content">
                  <h2
                    data-aos="fade-down"
                    data-aos-duration="600"
                    className="text-left"
                  >
                    Easy Moving Process
                  </h2>
                  <hr className="text-left" data-aos="fade" />

                  <p
                    className="text-black-50 "
                    data-aos="fade-down"
                    data-aos-duration="600"
                    data-aos-delay="300"
                  >
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Id
                    autem temporibus blanditiis accusamus perferendis libero
                    accusantium nisi itaque tempore, harum aliquid aut, sapiente
                    dolor tenetur. Tempora corrupti suscipit delectus
                    perspiciatis!Lorem ipsum dolor sit amet consectetur
                    adipisicing elit. Nostrum, voluptatibus.
                  </p>
                  <p
                    className="text-black-50"
                    data-aos="fade-down"
                    data-aos-duration="600"
                    data-aos-delay="400"
                  >
                    Tempora corrupti suscipit delectus perspiciatis!
                  </p>
                </div>
              </div>
              <div className="col-md-6 d-md-flex align-items-md-center p-0">
                <img
                  className="img-fluid"
                  data-aos="fade"
                  alt="Tempora"
                  data-aos-duration="600"
                  data-aos-delay="800"
                  src="https://cdn.pixabay.com/photo/2017/03/07/13/02/thought-2123971_960_720.jpg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="testimonials">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="subheader">
                  <h2>What our client's are saying</h2>
                  <hr className="mx-auto" />
                </div>
              </div>
              <div className="col">
                <MySwiper slidesperview={1}>
                  <div className="w-100">
                    <div className="info">
                      <p>
                        Leo in, vehicula rhoncus lacus. P leo in, vehicula
                        rhoncus lacus. P tortor est, vulputate quis leo in,
                        vehicula rhoncus lacus. Praesent aliquam Aliquam varius
                        finibus est.
                      </p>
                      <img
                        src={tstl1}
                        className="rounded-circle"
                        alt="testimonial"
                      />
                      <h5 className="mb-0">Ben Johnson</h5>
                      <span>CEO of Company, Inc.</span>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="info">
                      <p>
                        Leo in, vehicula rhoncus lacus. P leo in, vehicula
                        rhoncus lacus. P tortor est, vulputate quis leo in,
                        vehicula rhoncus lacus. Praesent aliquam Aliquam varius
                        finibus est.
                      </p>
                      <img
                        src={tstl2}
                        className="rounded-circle"
                        alt="testimonial"
                      />
                      <h5 className="mb-0">Ben Johnson</h5>
                      <span>CEO of Company, Inc.</span>
                    </div>
                  </div>
                  <div className="w-100">
                    <div className="info">
                      <p>
                        Leo in, vehicula rhoncus lacus. P leo in, vehicula
                        rhoncus lacus. P tortor est, vulputate quis leo in,
                        vehicula rhoncus lacus. Praesent aliquam Aliquam varius
                        finibus est.
                      </p>
                      <img
                        src={tstl3}
                        className="rounded-circle"
                        alt="testimonial"
                      />
                      <h5 className="mb-0">Ben Johnson</h5>
                      <span>CEO of Company, Inc.</span>
                    </div>
                  </div>
                </MySwiper>
              </div>
            </div>
          </div>
        </div>

        <div className="mission-n-vission">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 align-self-center">
                <div className="info mission">
                  <h2
                    data-aos="fade-down"
                    data-aos-delay="100"
                    className="text-center text-white"
                  >
                    <img
                      className="svg-icon"
                      src={mission}
                      alt="Company Mission"
                    />
                    MISSION
                  </h2>
                  <hr data-aos="fade" data-aos-delay="150" />
                  <h5
                    data-aos="fade-down"
                    data-aos-duration="600"
                    data-aos-delay="200"
                    className="my-4 text-white text-center"
                  >
                    To Create a less stressful Moving Experience for Our
                    Customers
                  </h5>
                </div>
              </div>
              <div className="col-md-6 align-self-center">
                <div className="info vision">
                  <h2
                    data-aos="fade-down"
                    data-aos-delay="100"
                    className="text-center text-white"
                  >
                    <img
                      className="svg-icon"
                      src={vision}
                      alt="Company Vision"
                    />
                    VISION
                  </h2>
                  <hr data-aos="fade" data-aos-delay="150" />
                  <h5
                    data-aos="fade-down"
                    data-aos-duration="600"
                    data-aos-delay="200"
                    className="my-4 text-white text-center"
                  >
                    To be the premiere local moving in the major cities of
                    Colorado.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="maps">
          <div className="container-fluid p-0">
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6213.559519116579!2d-104.72826585128321!3d38.86039814522603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8713463e3532de13%3A0x3a9e6853b547f3d5!2s5435%20Red%20Sky%20Dr%2C%20Colorado%20Springs%2C%20CO%2080915%2C%20USA!5e0!3m2!1sen!2sph!4v1580717461353!5m2!1sen!2sph"
                width="100%"
                height="500"
                frameborder="0"
                style={{ border: "0", marginBottom: "-6px" }}
              ></iframe>
            </div>
          </div>
        </div>

        <div className="call-to-action">
          <div className="subheader">
            <h2 className="text-white">Let's talk</h2>
            <p className="text-white-50 subheading">
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Blanditiis accusamus perferendis libero accusantium nisi.
            </p>
            <Modal className="text-white btn btn-primary btn-inverted mt-2 text-uppercase" />
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
